import { Platform } from "react-native";
import { flag } from "shared/utils/config";

let isTestLab = false;
if (Platform.OS === "android") {
  isTestLab = false;
}

const sim = false;
function getPlatformIdentifier() {
  return `${Platform.OS.toLocaleLowerCase()}-${
    isTestLab ? "testlab" : sim ? "simulator" : "device"
  }`;
}

function isDev() {
  let isDev =
    process.env.NODE_ENV === "development" ||
    isTestLab ||
    sim ||
    flag("INTERNAL_ANALYTICS_OPT_OUT", false);
  return isDev;
}

export { getPlatformIdentifier, isDev };
