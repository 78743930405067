import { Dimensions, Platform, Appearance } from "react-native";

let DynamicColorIOS = Platform.select({
  ios: require("react-native").DynamicColorIOS,
  android: () => {},
  web: () => {},
});

const Dynamic = {
  width: Platform.select({ web: 360, default: Dimensions.get("window").width }),
  height: Platform.select({
    web: 360,
    default: Dimensions.get("window").height,
  }),
  isSmallScreen: Dimensions.get("window").width <= 380,
  isSpeedEnabled: true,
  isSlow: false,
  isFast: false,
  appLaunchMS: 250,
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}
function colorKey(light, dark) {
  return `--${simpleHash(
    replaceAll(replaceAll(`${light}${dark}`, "#", ""), "rgba(", "")
  )}`;
}

const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

let colorCSSRules = new Map();

function lightDark(
  light,
  dark,
  highContrastLight = undefined,
  highContrastDark = undefined
) {
  if (Platform.OS === "web") {
    colorCSSRules.set(colorKey(light, dark), { light, dark });
  }
  return Platform.select({
    ios: DynamicColorIOS({
      dark: dark,
      light: light,
      highContrastDark,
      highContrastLight,
    }),
    android: Appearance.getColorScheme() === "dark" ? dark : light,
    web: `var(${colorKey(light, dark)})`,
  });
}

let colorCSSCache;

function getColorCSS() {
  if (colorCSSCache) {
    return colorCSSCache;
  } else if (colorCSSRules.size <= 10) {
    throw new Error("tried to get color CSS before rules where initialized");
  } else {
    const css = `
:root { ${[...colorCSSRules.values()]
      .map(({ dark, light }) => `${colorKey(light, dark)}:${light};`)
      .join("")}} @media (prefers-color-scheme: dark) { :root {${[
      ...colorCSSRules.values(),
    ]
      .map(({ dark, light }) => `${colorKey(light, dark)}:${dark};`)
      .join("")}}}`;
    const vars = `
    body {
      --primary: ${Colors.primary};
      --secondary: ${Colors.secondary};
      --background: ${Colors.background};
      --accent: ${Colors.accent};
      --card: ${Colors.card};
      --link: ${Colors.link};
    }
    `;
    colorCSSCache = `${css} ${vars}`;

    return colorCSSCache;
  }
}

const Gradients = {
  other: {
    black: ["#333", "#444"],
    darkgrey: ["#CFD8DC", "#546E7A"],
    soft: ["#CFD8DC", "#FAF2F7"],
    warn: ["#BF360C", "#F57C00"],
    blue: ["#2962FF", "#3949AB"],
    green: ["#2E7D32", "#4DB6AC"],
    violet: ["#4527A0", "#7B1FA2"],
    lightviolet: ["#D1C4E9", "#F3E5F5"],
    wild: ["#D500F9", "#FFA000"],
    revolut: ["#6200EA", "#1976D2"],
    accent: ["#FF0047", "#a3355d"],
    accentLight: ["#f6e9ed", "#f4d2df"],
  },
  apple: [
    [lightDark("#bbaac3", "#c1b0cc"), lightDark("#f4a183", "#f2a083")],
    [lightDark("#fe8981", "#fe8981"), lightDark("#fa6fb2", "#fa6fb2")],
    [lightDark("#fa6fb2", "#fa6fb2"), lightDark("#cd8cbc", "#cd8cbc")],
    [lightDark("#ebc24a", "#eac244"), lightDark("#e1cd70", "#e1cd70")],
    [lightDark("#edc358", "#edc356"), lightDark("#dd9572", "#e19774")],
    [lightDark("#23e1d2", "#56e4d3"), lightDark("#4fcea2", "#52e0b0")],
    [lightDark("#b85dc3", "#bf5dc7"), lightDark("#ed4889", "#ec4588")],
    [lightDark("#e650d9", "#e66bdc"), lightDark("#ee828e", "#f38694")],
    [lightDark("#e3585f", "#e26167"), lightDark("#f0b766", "#f9be68")],
    [lightDark("#8ddf9f", "#90e09e"), lightDark("#aac10d", "#b2ca0d")],
    [lightDark("#f876aa", "#f77cac"), lightDark("#fda188", "#feab97")],
    [lightDark("#41eac4", "#43eac2"), lightDark("#3ec3d0", "#42d0de")],
    [lightDark("#4ba3f7", "#4ba1f8"), lightDark("#7974dc", "#7875da")],
    [lightDark("#fa4d7b", "#fa4d7d"), lightDark("#b75bb6", "#bf5ec1")],
    [lightDark("#c76781", "#c46684"), lightDark("#fea245", "#fd9f43")],
    [lightDark("#c8cd5a", "#c7cd56"), lightDark("#dca35c", "#dea55d")],
  ],
  ui: [
    ["#7F00FF", "#E100FF"],
    ["#ff9966", "#ff5e62"],
    ["#800080", "#ffc0cb"],
    ["#642B73", "#C6426E"],
    ["#36D1DC", "#5B86E5"],
    ["#CB356B", "#BD3F32"],
    ["#3A1C71", "#D76D77", "#FFAF7B"],
    ["#283c86", "#45a247"],
    ["#000046", "#1CB5E0"],
    ["#2F80ED", "#56CCF2"],
    ["#30E8BF", "#FF8235"],
    ["#20002c", "#cbb4d4"],
    ["#C33764", "#1D2671"],
    ["#4568DC", "#B06AB3"],
    ["#67B26F", "#4ca2cd"],
  ],
};

const Colors = {
  primary: lightDark("#000000", "#ffffff"),
  secondary: lightDark("#666666", "#aaaaaa", "#222222", "#dddddd"),
  background: lightDark("#FFFFFF", "#1F1F1F"),
  bg: {
    input: lightDark("#eee", "#444"),
    disabled: lightDark("#ddd", "#666"),
    disabledGradient: lightDark("#eee", "#777"),
  },
  shadow: lightDark(
    Platform.select({
      default: "#cfcfcf",
      android: "#999999",
      web: "rgba(100,100,100,0.15)",
    }),
    Platform.select({
      default: "#000000",
      web: "rgba(0,0,0,0.2)",
    })
  ),
  strongShadow: lightDark("rgba(0,0,0,0.3)", "rgba(0,0,0,0.8)"),
  card: lightDark(
    "#FFFFFF",
    Platform.select({ default: "#303030", web: "#292929" }),
    "#ffffff",
    "#333333"
  ),
  accent: lightDark("#ff0047", "#ff0047"),
  link: lightDark("rgba(0, 122, 255, 1)", "rgba(10, 132, 255, 1)"),
  warning: lightDark("#dd4444", "#ff4444"),
  tabs: {
    active: lightDark("#FF0047", "#ffffff"),
    inactive: lightDark("#777777", "#888888", "#444444", "#AAAAAA"),
    background: lightDark("#ffffff", "#000000"),
  },
  ios: {
    blue: lightDark("rgba(0, 122, 255, 1)", "rgba(10, 132, 255, 1)"),
    green: lightDark("rgba(52, 199, 89, 1)", "rgba(48, 209, 88, 1)"),
    indigo: lightDark("rgba(88, 86, 214, 1)", "rgba(94, 92, 230, 1)"),
    orange: lightDark("rgba(255, 149, 0, 1)", "rgba(255, 159, 10, 1)"),
    pink: lightDark("rgba(255, 48, 85, 1)", "rgba(255, 55, 95, 1)"),
    purple: lightDark("rgba(175, 82, 222, 1)", "rgba(191, 90, 242, 1)"),
    red: lightDark("rgba(255, 59, 48, 1)", "rgba(255, 69, 58, 1)"),
    teal: lightDark("rgba(90, 200, 250, 1)", "rgba(100, 210, 255, 1)"),
    yellow: lightDark("rgba(255, 204, 0, 1)", "rgba(255, 214, 10, 1)"),
  },
  generic: {
    black: "#000000",
    white: "#FFFFFF",
  },
  greyscale: ["#000", "#111", "#444", "#777", "#aaa", "#ddd", "#eee", "#fff"],
  transparent: {
    background: [
      lightDark("#ffffff00", "#1F1F1F00"),
      lightDark("#ffffff11", "#1F1F1F11"),
      lightDark("#ffffff44", "#1F1F1F44"),
      lightDark("#ffffff77", "#1F1F1F77"),
      lightDark("#ffffffaa", "#1F1F1Faa"),
      lightDark("#ffffffdd", "#1F1F1Fdd"),
      lightDark("#ffffffee", "#1F1F1Fee"),
      lightDark("#ffffffff", "#1F1F1Fff"),
    ],
    primary: [
      lightDark("#00000000", "#ffffff00"),
      lightDark("#00000011", "#ffffff11"),
      lightDark("#00000022", "#ffffff44"),
      lightDark("#00000077", "#ffffff77"),
      lightDark("#000000aa", "#ffffffaa"),
      lightDark("#000000dd", "#ffffffdd"),
      lightDark("#000000ee", "#ffffffee"),
      lightDark("#000000ff", "#ffffffff"),
    ],
  },
};
const Spacing = {
  betweenHalf: 8,
  between: 16,
  betweenDouble: 32,
  webContentWidth: 960,
  topBarHeight: Platform.select({
    ios: Dimensions.get("window").height >= 812 ? 88 : 64,
    android: 54,
  }),
  statusBarHeight: Platform.select({
    ios: Dimensions.get("window").height >= 812 ? 42 : 20,
    android: 0,
  }),
  navBarHeight: Platform.select({
    ios: Dimensions.get("window").height >= 812 ? 46 : 44,
    android: 54,
  }),
  categoryBarSize: 54,
};

const Radius = {
  sharp: 4,
  medium: 8,
  round: 16,
};

const Shadows = {
  card: Platform.select({
    default: {
      shadowColor: Colors.shadow,
      shadowOpacity: Platform.select({ default: 0.6, android: 1 }),
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: Spacing.between,
      elevation: 20,
    },
    web: {
      shadowColor: Colors.shadow,
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: Spacing.between + 4,
    },
  }),
  soft: {
    shadowColor: Colors.shadow,
    shadowOpacity: Platform.select({ default: 0.3, android: 0.5 }),
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: Spacing.betweenHalf,
    elevation: 10,
  },
  selected: {
    shadowColor: Colors.strongShadow,
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: Spacing.between,
    elevation: 3,
  },
};

export {
  Colors,
  Spacing,
  Radius,
  Shadows,
  Dynamic,
  getColorCSS,
  Gradients,
  lightDark,
};
