import posthog from "posthog-js";

async function initPosthog(token, host) {
  return posthog.init(token, {
    api_host: host,
    autocapture: false,
  });
}

export { posthog, initPosthog };
