import gql from "graphql-tag";
import { messages } from "shared/data/messages";
import get from "lodash/get";
import { useCallback, useEffect } from "react";
import { useMutation } from "urql";
import { rawStore } from "shared/utils/store";

let flags = {};
let text = messages;
let config = {};
let usageCounters = {};

const INCREMENT_COUNTER = gql`
  mutation IncrementUsageCounter($item_id: String) {
    add: insert_usage_counters(
      objects: { item_id: $item_id }
      on_conflict: { constraint: usage_counters_pkey }
    ) {
      affected_rows
    }
    inc: update_usage_counters(
      where: { item_id: { _eq: $item_id } }
      _inc: { count: 1 }
    ) {
      returning {
        id
        count
      }
    }
  }
`;

function useUsageCounter(feature) {
  const [_, incrementCounter] = useMutation(INCREMENT_COUNTER);
  const countUp = useCallback(() => {
    try {
      incrementCounter({ item_id: feature });
    } catch (e) {
      console.error("cannot execute mutation", e);
    }
  }, [feature, incrementCounter]);

  return countUp;
}

function useScreenCounter(feature) {
  const [_, incrementCounter] = useMutation(INCREMENT_COUNTER);
  const countUp = useCallback(() => {
    try {
      incrementCounter({ item_id: feature });
    } catch (e) {
      console.error("cannot execute mutation", e);
    }
  }, [feature, incrementCounter]);
  useEffect(() => {
    countUp();
  }, [countUp, feature]);
}

function useMountCounter(feature) {
  const [_, incrementCounter] = useMutation(INCREMENT_COUNTER);
  const countUp = useCallback(() => {
    try {
      incrementCounter({ item_id: feature });
    } catch (e) {
      console.error("cannot execute mutation", e);
    }
  }, [feature, incrementCounter]);
  useEffect(() => {
    countUp();
  }, [countUp, feature]);
}

function flag(key: string, fallback: boolean) {
  return get(flags, key, fallback);
}

function usage(feature: string): number {
  return get(usageCounters, feature, 0);
}

function t(key: string, fallback: string = "") {
  return get(text, key, fallback);
}

function conf<T>(path: string, fallback: T = undefined): T {
  return get(config, path, fallback);
}

function initializeConfigFromCache() {
  const cached = rawStore.getState().remoteConf;
  config = cached.config;
  flags = cached.flags;
  text.remote = cached.text;
  usageCounters = cached.usageCounters;
}

function initializeConfigWithData(data) {
  try {
    if (data?.flags?.config) {
      const merged = {
        ...data.flags.config,
        ...data.users[0].feature_flags,
        ...data.users[0].internal_flags,
      };
      flags = merged;
    } else {
      console.info("[config] failed to init feature flags");
    }
    if (data?.text?.config) {
      text.remote = data.text.config;
    } else {
      console.info("[config] failed to init remote text");
    }
    if (data?.config?.config) {
      config = data.config.config;
    } else {
      console.info("[config] failed to init remote config");
    }
    if (data.users[0].usage_counters) {
      usageCounters = Object.fromEntries(
        data.users[0].usage_counters.map((u) => [u.item_id, u.count])
      );
    }
    if (data?.flags?.config) {
      rawStore.getState().updateConfigCache({
        text: text.remote,
        flags: flags,
        config: config,
        usageCounters: usageCounters,
      });
    }
  } catch (e) {
    console.error(String(e));
  }
}

export {
  initializeConfigFromCache,
  initializeConfigWithData,
  usage,
  flag,
  t,
  conf,
  useUsageCounter,
  useScreenCounter,
  useMountCounter,
};
