import create from "zustand";
import { subscribeWithSelector, combine } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

const rawStore = create(
  subscribeWithSelector(
    combine(
      {
        introSeen: false,
        timelineIntroSeen: false,
        online: true,
        wasAskedAboutReminders: false,
        optimisticUserID: null,
        remoteConf: {
          config: {},
          flags: {},
          usageCounters: {},
          text: {},
        },
      },
      (set) => ({
        restore: (fromStorage) => set(() => fromStorage),
        didSeeIntro: () => set(() => ({ introSeen: true })),
        didSeeTimelineIntro: () => set(() => ({ timelineIntroSeen: true })),
        wentOnline: () => set(() => ({ online: true })),
        wentOffline: () => set(() => ({ online: false })),
        setIsOnline: (online: boolean) => set(() => ({ online })),
        updateConfigCache: (remoteConf: {
          config: any;
          flags: any;
          usageCounters: any;
          text: any;
        }) => set(() => ({ remoteConf })),
        firstSignIn: (userID: number) =>
          set(() => ({ optimisticUserID: userID })),
      })
    )
  )
);

const useStore = rawStore;

const ephemeralKeys = ["online"];

function filterState(state: object) {
  return Object.fromEntries(
    Object.entries(state).filter(([key, value]) => {
      return !ephemeralKeys.includes(key) && typeof value !== "function";
    })
  );
}

async function initStore() {
  const data = await AsyncStorage.getItem("persist-store").then(JSON.parse);
  rawStore.getState().restore(data);

  rawStore.subscribe(
    (state) => filterState(state),
    (state) => {
      AsyncStorage.setItem("persist-store", JSON.stringify(filterState(state)));
    },
    { equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b) }
  );
}

export { useStore, rawStore, initStore };
