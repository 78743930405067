import React, { useContext, createContext, useCallback } from "react";
import { Appearance, AccessibilityInfo, Platform } from "react-native";
import { Sentry } from "shared/shims/sentry";
import * as Application from "expo-application";
import { getPlatformIdentifier, isDev } from "shared/utils/deviceInfo";
import { Dynamic } from "shared/utils/theme";
import { posthog, initPosthog } from "shared/shims/posthog";
import Constants from "expo-constants";

const routingInstrumentation =
  Platform.OS === "web" ? null : new Sentry.RoutingInstrumentation();

function initSentry() {
  if (Platform.OS !== "web") {
    Sentry.init({
      dsn: Constants.expoConfig?.extra?.sentryDSNMobile,
      environment: isDev() ? "development" : "production",
      integrations: [
        new Sentry.ReactNativeTracing({
          tracingOrigins: [
            "https://data.kulta.app/v1/graphql",
            "https://data.kulta.app/v1/graphql/",
            "data.kulta.app",
            "*kulta.app*",
            "*",
          ],
          routingInstrumentation,
        }),
      ].filter(Boolean),
      maxBreadcrumbs: 15,
      tracesSampleRate: 1.0,
      enableAutoSessionTracking: true,
    });

    Sentry.setTags({
      NODE_ENV: process.env.NODE_ENV,
      version_json: Constants.expoConfig?.version,
      version_build: Constants.expoConfig?.ios.buildNumber,
    });

    let launchT = Sentry.startTransaction({
      name: "launch",
      op: "launch",
    });
    Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(launchT));
  } else {
    /*
    Sentry.init({
      dsn: Constants.expoConfig?.extra?.sentryDSNWeb,
      environment: isDev() ? "development" : "production",
    });

    Sentry.setTags({
      NODE_ENV: process.env.NODE_ENV,
      version_json: Constants.expoConfig?.version,
      version_build: Constants.expoConfig?.ios?.buildNumber || 0,
    });

    let launchT = Sentry.startTransaction({
      name: "launch",
      op: "launch",
    });
    Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(launchT));*/
  }
}

function resetLaunchTransaction() {
  let relaunchT = Sentry.startTransaction({
    name: "relaunch",
    op: "launch",
  });
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(relaunchT));
}

function initTracking() {
  initPosthog(
    Platform.OS === "web"
      ? Constants.expoConfig?.extra?.posthogTokenWeb
      : Constants.expoConfig?.extra?.posthogTokenMobile,
    Constants.expoConfig?.extra?.posthogURL
  );
  initSentry();
}

const TrackingContext = createContext<{ context: any; props: TrackingProps }>({
  context: {},
  props: {},
});

export interface TrackingProps {
  actionType?: string;
  resourceType?: string;
  resourceId?: string;
  resourceDate?: string;
  resourceName?: string;
  resourceLocation?: string;
  resourceCategory?: string;
  sourceType?: string;
  sourceId?: string;
  referrerType?: string;
  referrerId?: string;
  message?: string;
  extraName?: string;
  extraValue?: string;
  screen?: string;
  deprecated?: boolean;
  online?: boolean;
}

function TrackData({
  children,
  context = {},
  props = {},
}: {
  children?: any;
  context?: any;
  props?: TrackingProps;
}) {
  const { context: oldContext, props: oldProps } = useContext(TrackingContext);
  return (
    <TrackingContext.Provider
      value={{
        props: { ...oldProps, ...props },
        context: { ...oldContext, ...context },
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}

function currentTransaction() {
  let current = Sentry.getCurrentHub().getScope().getTransaction();
  if (!current || current.endTimestamp > 0) {
    let transaction = Sentry.startTransaction({
      name: "Orphan Transaction",
      op: "noop",
    });
    return transaction;
  } else {
    return current;
  }
}

function useTrack() {
  const contextData = useContext(TrackingContext);
  const callback = useCallback(
    (name = "Unkown Event", props: TrackingProps = {}, context = {}) =>
      track(
        name,
        { ...contextData.props, ...props },
        { ...contextData.context, ...context }
      ),
    [contextData.context, contextData.props]
  );
  return callback;
}

function track(event = "Unknown Event", props: any = {}, context = {}) {
  if (props.actionType !== "error") {
    Sentry.addBreadcrumb({
      type: "user",
      message: event,
      data: { context, props },
      level: "log",
    });
  }
  posthog.capture(event, { ...props, ...context });
}

function trackScreen(screenname, props: TrackingProps = {}, context = {}) {
  if (props.actionType !== "error") {
    Sentry.addBreadcrumb({
      type: "user",
      message: `Screen: ${screenname}`,
      data: { context, props },
      level: "log",
    });
  }
  posthog.capture(`Screen: ${screenname}`, { ...props, ...context });
}

/** Send an exception to __Sentry__, send a `Capture Exception` event to __Posthog__ and log error to __console__ */
function capture(
  error: string | Error,
  context?: {
    extra?: Record<string, any>;
    tags?: Record<string, string>;
  }
) {
  posthog.capture(`Capture Exception`, {
    isDebugMessage: true,
    e: error ? JSON.stringify(error) : undefined,
    extra: context ? JSON.stringify(context) : undefined,
  });

  console.error(error);
  Sentry.captureException(error, context);
}

function log(message, extra?: any) {
  if (isDev()) {
    console.info(message, extra);
    return false;
  }
  Sentry.captureMessage(message);
  if (extra) {
    console.info(message, extra);
  } else {
    console.info(message);
  }
  posthog.capture("log", {
    isDebugMessage: true,
    message,
    extra: extra ? JSON.stringify(extra) : undefined,
  });

  return true;
}

async function identifyToAnalytics(withUserID: string) {
  const context = {
    environment: isDev() ? "development" : "production",
    darkMode: Appearance.getColorScheme(),
    a11y_vo: await AccessibilityInfo.isScreenReaderEnabled(),
    isSmallScreen: Boolean(Dynamic.isSmallScreen),
    isSlow: Boolean(Dynamic.isSlow),
  };

  const userProps = {
    internal: process.env.NODE_ENV === "development",
    kultaRegion: "muenster",
    last_platform: getPlatformIdentifier(),
  };

  // Sentry
  Sentry.setUser({ id: withUserID, ...userProps });
  Sentry.setTags(context);

  posthog.identify(withUserID, {
    ...userProps,
    ...context,
  });
}

function startTransaction({ name, op = "ACTION" }) {
  let transaction = Sentry.startTransaction({
    name,
    op,
  });
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
  return transaction;
}

const useProfiler = Sentry.useProfiler;

export {
  initTracking,
  useTrack,
  track,
  log,
  trackScreen,
  useProfiler,
  TrackData,
  capture,
  identifyToAnalytics,
  currentTransaction,
  startTransaction,
  routingInstrumentation,
  resetLaunchTransaction,
};
