import { GraphQLClient } from "graphql-request";
import {
  createClient,
  dedupExchange,
  fetchExchange,
  errorExchange,
} from "urql";

const myAuthExchange = makeAuthExchange();

import { makeAuthExchange } from "shared/utils/auth";

function createURQL() {
  return createClient({
    url: "https://data.kulta.app/v1/graphql",
    fetchOptions: () => {
      return {
        headers: { "X-Hasura-Role": "profile" },
      };
    },
    exchanges: [
      dedupExchange,
      myAuthExchange,
      errorExchange({
        onError(error) {
          console.log(error);
        },
      }),
      fetchExchange,
    ],
  });
}

function getClient({ role = "appUser" } = { role: "appUser" }) {
  return new GraphQLClient("https://data.kulta.app/v1/graphql", {
    headers: {
      "X-Hasura-Admin-Secret": process.env.ADMIN_SECRET,
      "X-Hasura-Role": role,
      "X-Hasura-User-Id": "xBcRvtlT1JX8IAx2w2uZECCAKft1",
    },
  });
}

export { getClient, createURQL };
