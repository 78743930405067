import React, { useEffect, useMemo } from "react";
import { useContext, useCallback, createContext, useState } from "react";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { authExchange } from "@urql/exchange-auth";
import { makeOperation } from "@urql/core";
import gql from "graphql-tag";
import { getPlatformIdentifier } from "shared/utils/deviceInfo";

interface AuthState {
  token: string;
  userID: string;
  expirationTime: string;
}

function validForSeconds(expirationTime) {
  const validSeconds =
    (new Date(expirationTime).getTime() - new Date().getTime()) / 1000;
  return validSeconds;
}

const SIGNIN = gql`
  mutation SignIn($userID: String, $platform: String, $email: String) {
    insert_users_one(
      object: { id: $userID, last_platform: $platform, email: $email }
      on_conflict: {
        update_columns: [last_seen, last_platform]
        constraint: users_pkey
      }
    ) {
      id
      __typename
    }
    add: insert_usage_counters(
      objects: { item_id: "signin" }
      on_conflict: { constraint: usage_counters_pkey }
    ) {
      __typename
      affected_rows
    }
    inc: update_usage_counters(
      where: { item_id: { _eq: "signin" } }
      _inc: { count: 1 }
    ) {
      __typename
      affected_rows
    }
  }
`;

function makeAuthExchange() {
  const myAuthExchange = authExchange({
    getAuth: async ({
      authState,
      mutate,
    }: {
      authState: AuthState;
      mutate: Function;
    }): Promise<AuthState | null> => {
      let token, userID, expirationTime, email;
      let isVenue = false;
      try {
        let a = await getAuth().currentUser.getIdTokenResult();
        token = a.token;
        userID = a.claims.user_id;
        expirationTime = a.expirationTime;
        if (a.claims.email) {
          isVenue = true;
          email = a.claims.email;
        }
      } catch (e) {
        console.log(e);
      }
      if (token && isVenue) {
        if (!authState) {
          // is first launch
          if (process.env.NODE_ENV === "development") {
            console.info("User ID", userID);
          }
        }
        if (!authState) {
          // is first launch
          try {
            const signinResult = await mutate(
              SIGNIN,
              {
                userID,
                email,
                platform: getPlatformIdentifier(),
              },
              {
                fetchOptions: {
                  headers: { Authorization: `Bearer ${token}` },
                },
              }
            );
          } catch (e) {
            console.log(e);
          }
        }
        return { token, userID, expirationTime };
      } else {
        return null;
      }
    },
    addAuthToOperation: ({
      authState,
      operation,
    }: {
      authState: AuthState;
      operation: any;
    }) => {
      if (!authState || !authState.token) {
        return operation;
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === "function"
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${authState.token}`,
            "X-Hasura-Role": "profile",
          },
        },
      });
    },
    didAuthError: ({ error }) => {
      const result =
        // @ts-ignore
        error.graphQLErrors.some((e) => e.response.status === 401) ||
        error.graphQLErrors.some((e) => e.message.includes("JWTExpired"));
      return result;
    },
    willAuthError: ({ authState }: { authState: AuthState }) => {
      if (!authState) {
        return true;
      }
      const validSeconds = validForSeconds(authState.expirationTime);
      const isExpired = validSeconds <= 0;
      return isExpired;
    },
  });
  return myAuthExchange;
}

const AuthContext = createContext<{
  login: (email: string) => Promise<boolean>;
  logout: () => void;
  signUp: (email: string) => Promise<boolean>;
  openLogin: () => void;
  openSignup: () => void;
  getToken: () => string;
  signedIn: boolean;
  token: string;
  username: string;
  userID: string;
  loaded: boolean;
}>(null);

function useAuth() {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return auth;
}

function AuthProvider({ children }) {
  const firebase = useMemo(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyAXYuQxgqWGcNPW5FAZD4b69HF5CzHOUbg",
      authDomain: "api-8895446792420689004-72938.firebaseapp.com",
      databaseURL: "https://api-8895446792420689004-72938.firebaseio.com",
      projectId: "api-8895446792420689004-72938",
      storageBucket: "api-8895446792420689004-72938.appspot.com",
      messagingSenderId: "291737156092",
      appId: "1:291737156092:web:2e87fde3b94d93d30b5a79",
    };

    // Initialize Firebase
    return initializeApp(firebaseConfig);
  }, []);
  const auth = useMemo(() => getAuth(firebase), [firebase]);
  const [signedIn, setSignedIn] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [token, setToken] = useState<string>(null);
  const [username, setUserName] = useState<string>(null);
  const [userID, setUserID] = useState<string>(null);

  const login = useCallback(
    (email: string): Promise<boolean> => {
      return sendSignInLinkToEmail(auth, email, {
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/shortcut/profile"
            : "https://kulta.app/shortcut/profile",
        handleCodeInApp: true,
        iOS: {
          bundleId: "de.kultabunt.kultabunt",
        },
        android: {
          packageName: "com.kulta",
          installApp: true,
          minimumVersion: "12",
        },
        dynamicLinkDomain: "kulta.page.link",
      })
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          console.log("stored email", email);
          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },
    [auth]
  );
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          //email = window.prompt("Please provide your email for confirmation");
        } else {
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              window.localStorage.removeItem("emailForSignIn");

              // console.log("new", result.additionalUserInfo.isNewUser);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    }
  }, [auth]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      auth.onAuthStateChanged((state) => {
        if (state?.getIdTokenResult) {
          state.getIdTokenResult().then(console.log);
        }
        setLoaded(true);
        setSignedIn(Boolean(state?.providerId));
        setUserID(state?.uid);
        setUserName(state?.email);
      });
      auth.onIdTokenChanged((state) => {
        if (state?.getIdToken) {
          state.getIdToken().then((token) => setToken(token));
        } else {
          setToken(null);
        }
      });
    }
  }, [auth]);
  const logout = useCallback(() => {
    auth.signOut();
  }, [auth]);
  const signUp = useCallback(
    (email: string): Promise<boolean> => {
      return sendSignInLinkToEmail(auth, email, {
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/auth/create"
            : "https://kulta.app/auth/create",
        handleCodeInApp: true,
        iOS: {
          bundleId: "de.kultabunt.kultabunt",
        },
        android: {
          packageName: "com.kulta",
          installApp: true,
          minimumVersion: "12",
        },
        dynamicLinkDomain: "kulta.page.link",
      })
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          console.log("stored email", email);
          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },
    [auth]
  );
  const openLogin = useCallback(() => {}, []);
  const openSignup = useCallback(() => {}, []);
  const getToken = useCallback(() => {
    return token;
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        openLogin,
        userID,
        openSignup,
        login,
        logout,
        loaded,
        getToken,
        signedIn,
        token,
        username,
        signUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function SignedIn({ children, fallback = null }) {
  const { signedIn } = useAuth();
  if (signedIn) {
    return children;
  } else {
    return fallback;
  }
}

function SignedOut({ children, fallback = null }) {
  const { signedIn } = useAuth();
  if (!signedIn) {
    return children;
  } else {
    return fallback;
  }
}

function Loaded({ children, fallback = null }) {
  const { loaded } = useAuth();
  if (loaded) {
    return children;
  } else {
    return fallback;
  }
}

export { useAuth, AuthProvider, SignedIn, SignedOut, Loaded, makeAuthExchange };
